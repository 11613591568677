<!--
 * @Author: zhouyajuan
 * @Date: 2020-10-14 13:40:59
 * @LastEditTime: 2021-03-22 10:00:59
 * @LastEditors: yangliao
 * @Description: 立即抄表
 * @FilePath: /netHallOfficialAccounts/src/views/SelfMeterRead/selfMeterReadImmediately.vue
-->
<template>
  <div class="selfMeterReadImmediately-page">
    <div class="immediately-topContent" style="margin-top:16px;">
      <em>户号</em>
      <div class="adressSpan">
        <p>{{ form.clientCode }}</p>
        <!-- <p>{{dataInfo.address2}}</p> -->
      </div>
    </div>
    <div class="immediately-topContent">
      <em>水表编号</em>
      <div class="adressSpan">
        <p>{{ form.meterCode }}</p>
        <!-- <p>{{dataInfo.address2}}</p> -->
      </div>
    </div>
    <div class="immediately-topContent">
      <em>地址</em>
      <div class="adressSpan">
        <p>{{ form.meterAddress }}</p>
        <!-- <p>{{dataInfo.address2}}</p> -->
      </div>
    </div>

    <div class="immediately-centerContent">
      <div class="preRecord">
        <span
          >上次读数 <i>{{ form.meterNow }}</i></span
        >
        <em>{{ form.meterCheckTime }}</em>
      </div>
      <div class="readRecord">
        <van-form>
          <van-field
            class="enter-num"
            v-model="currentRead"
            placeholder="填写当前读数"
            type="digit"
            maxlength="10"
            :rules="[{ validator: validatorNumber, message: '' }]"
          />
        </van-form>
      </div>
      <div class="readInfo">
        <span>请仔细查看水表后填写</span>
        <em @click="showExample">查看示例</em>
      </div>
    </div>
    <div class="immediately-bottomContent">
      <div class="txtInfo">
        <span>水表拍照</span>
      </div>
      <div class="photo">
        <van-field name="uploader">
          <template #input>
            <van-uploader v-model="uploader" :max-count="1" :after-read="afterRead" @delete="deleteValue" />
          </template>
        </van-field>
      </div>
      <div class="txtInfo">
        <span>请对准水表拍摄，如环境较暗请开闪光灯</span>
      </div>
      <!-- <van-field v-model="message" label="留言" type="textarea" placeholder="请输入留言" rows="1" autosize /> -->
      <van-field
        name="remark"
        v-model="remark"
        placeholder="请输入备注"
        label="备注"
        type="textarea"
        :autosize="{ maxHeight: 100, minHeight: 50 }"
        border
      >
      </van-field>
    </div>

    <div class="btnContent">
      <van-button type="info" block @click="submitContent" :loading="submitLoading" loading-text="提交中">
        提交
      </van-button>
    </div>

    <!-- 弹出框 -->
    <van-popup v-model="show" :style="{ height: '100%', width: '100%' }">
      <div class="dialogContent" @click="show = false"></div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant';
// import exampleImg from '../../assets/background/example.png';
import { selfMeterReadInfo, uploadPictureBase64, readInfo } from '@/api/home';
import { addSelfMeterRead } from '@/api/business';
import store from '@/store';
import { mapState } from 'vuex';
import moment from 'moment';
export default {
  name: 'ReadMeter',
  data() {
    return {
      show: false,
      dataInfo: {},
      readRecordValue: '',
      uploader: [],
      currentRead: '',
      imgId: '',
      // exampleImg,
      index: this.$route.query.index,
      form: {},
      submitLoading: false,
      remark: ''
    };
  },
  computed: {
    ...mapState('user', {
      homeInfo: (state) => state.homeInfo,
      businessNews: (state) => state.businessNews
    })
  },
  mounted() {
    console.log('homeInfo', this.homeInfo);

    this.form = JSON.parse(this.$route.query.info);
    // this.selfMeterReadInfo();
  },
  methods: {
    async selfMeterReadInfo() {
      const { status, resultData } = await selfMeterReadInfo();
      if (status === 'complete') {
        this.dataInfo = resultData[this.index];
        // console.log('数据--', this.dataInfo)
      }
    },
    validatorNumber(val) {
      if (Number(val) < Number(this.dataInfo.lastTimeRead)) {
        return Toast('当前读数不能小于上次读数');
      }
    },
    showExample() {
      this.show = true;
    },
    // 此时可以自行将文件上传至服务器
    async afterRead(file) {
      console.log(file);
      let suffix = file.file.name.split('.')[1];
      let arg = {
        endWith: suffix,
        imageString: file.content,
        returnFlag: false,
        pictureRealName: file.file.name
      };
      this.imgId = '';
      this.uploader = [];
      const { status, resultData } = await uploadPictureBase64(arg);
      if (status === 'complete') {
        this.imgId = resultData;
        this.uploader.push(file);
      }
    },
    // 删除文件
    deleteValue() {
      this.imgId = '';
    },
    async submitContent() {
      const { accountMonth, meterNow, readCycle, clientCode, meterCode } = this.form;
      // 比较
      if (Number(this.currentRead) < Number(meterNow)) {
        return Toast('当前读数不能小于上次读数');
      }
      if (this.imgId) {
        let currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
        let arg = {
          accountMonth: accountMonth,
          currentMeterImage: this.imgId,
          currentRead: this.currentRead,
          lastTimeRead: meterNow,
          readCycle: readCycle,
          userCode: clientCode,
          meterCode,
          remark: this.remark,
          startTime: this.form.meterCheckTime,
          clientName: this.form.clientName,
          meterAddress: this.form.meterAddress,
          mobile: this.form.mobile,
          tel: this.form.tel,
          meterCheckTime: this.form.meterCheckTime
        };

        this.submitLoading = true;
        try {
          const { status, resultData } = await addSelfMeterRead(arg);
          if (status === 'complete') {
            this.submitLoading = false;
            Toast('抄表成功');
            this.$router.go(-1);
          }
        } catch (error) {
          console.log(error);
          this.submitLoading = false;
        }
      } else {
        return Toast('请上传水表照片');
      }
    }
  }
};
</script>

<style lang="less" scoped>
.selfMeterReadImmediately-page {
  height: calc(100vh - 60px);
  overflow: auto;
  .immediately-topContent {
    margin: 0 16px;
    border-radius: 8px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 0;
    em {
      font-style: normal;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
    }
    .adressSpan {
      p {
        margin: 0px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        text-align: right;
        line-height: 22px;
      }
    }
    /deep/.van-field__control {
      text-align: right;
    }
  }
  .immediately-centerContent {
    margin: 16px;
    border-radius: 8px;
    background: #ffffff;
    padding-left: 16px;
    padding-right: 16px;
    .preRecord {
      // border-bottom: 1px solid rgba(0,0,0,0.08);
      position: relative;
      height: 49px;
      line-height: 49px;
      display: flex;
      justify-content: space-between;
      span {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        i {
          font-style: normal;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      em {
        font-style: normal;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.45);
        text-align: center;
      }
    }
    .preRecord::after {
      position: absolute;
      box-sizing: border-box;
      content: ' ';
      pointer-events: none;
      right: 0px;
      bottom: 0;
      left: 0px;
      border-bottom: 1px solid #ebedf0;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
    .readRecord {
      position: relative;
      /deep/.van-field__control {
        text-align: center;
        height: 49px;
        line-height: 49px;
        font-family: PingFangSC-Regular;
        font-size: 24px;
      }
      /deep/.van-field__error-message {
        text-align: center;
      }
    }
    .readRecord::after {
      position: absolute;
      box-sizing: border-box;
      content: ' ';
      pointer-events: none;
      right: 0px;
      bottom: 0;
      left: 0px;
      border-bottom: 1px solid #ebedf0;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
    .readInfo {
      display: flex;
      justify-content: space-between;
      height: 48px;
      line-height: 48px;
      span {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
      }
      em {
        font-style: normal;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #3774bc;
      }
    }
  }
  .immediately-bottomContent {
    margin: 16px;
    background: #ffffff;
    border-radius: 8px;

    .txtInfo {
      position: relative;
      padding-left: 16px;
      height: 50px;
      line-height: 50px;
      span {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
      }
    }
    .txtInfo::after {
      position: absolute;
      box-sizing: border-box;
      content: ' ';
      pointer-events: none;
      right: 16px;
      bottom: 0;
      left: 16px;
      border-bottom: 1px solid #ebedf0;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
    .txtInfo::before {
      position: absolute;
      box-sizing: border-box;
      content: ' ';
      pointer-events: none;
      right: 16px;
      top: 0;
      left: 16px;
      border-top: 1px solid #ebedf0;
      -webkit-transform: scaleY(0.5);
      transform: scaleY(0.5);
    }
    // .txtInfo:last-child::after {
    //     position: absolute;
    //     box-sizing: border-box;
    //     content: ' ';
    //     pointer-events: none;
    //     right: 16px;
    //     top: 0;
    //     left: 16px;
    //     border-top: 0px solid transparent;
    //     -webkit-transform: scaleY(.5);
    //     transform: scaleY(.5);
    // }
    .photo {
      /deep/.van-uploader {
        margin: 0 auto;
        border: solid 1px #d7d7d7;
        border-radius: 8px;
      }
      // /deep/.van-cell__value {
      //   background: rgba(246, 246, 246, 0.5);
      // }
      /deep/.van-uploader__upload {
        // background: rgba(246, 246, 246, 0.5);
        margin: 0;
      }
      /deep/ .van-uploader__preview {
        margin: 0;
      }
    }
  }
  .btnContent {
    padding-left: 16px;
    padding-right: 16px;
    /deep/.van-button--block {
      width: 90%;
      position: fixed;
      bottom: 10px;
      border-radius: 8px;
    }
  }
  /deep/.van-popup {
    width: 80%;
    height: 60%;
  }
  .dialogContent {
    // margin-top: 12%;
    width: 100%;
    height: 440px;
    background: url('~@/assets/background/example_new.png') no-repeat 3px -50px;
    background-size: cover;
  }
}
.enter-num {
  border: solid 1px #d7d7d7;
  border-radius: 8px;
}
</style>
